import React, { useState, useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

import SEO from '../components/seo';
import Footer from '../sections/Footer';
import Wrap from '../components/Wrap';

const NotFoundPage = () => {
  const [aosInit, setAosInit] = useState(false);

  useEffect(() => {
    if (!aosInit) {
      AOS.init({
        duration: 600,
        easing: 'ease-in-sine',
      });
      setAosInit(true);
    } else {
      AOS.refreshHard();
    }
  });

  return (
    <>
      <SEO title="Privacy policy" />
      <Wrap>
        <h2>Firstversionist</h2>
        <h1>Privacy Policy</h1>
        <p>
          <em>Effective date: March 19, 2019</em>
        </p>
        <p>
          This Privacy Policy informs you of the policies regarding the collection, use and disclosure of personal data
          when using our services by Firstversionist B.V. and its respective affiliates ("us", "we", "our" or
          “Firstversionist”), This Privacy Policy describes our privacy practices in relation to the use of the
          https://firstversionist.com, https://polypane.rocks, https://superposition.design and
          https://fromscratch.rocks websites and associated subdomains, customer portals or interactive customer
          website, our software, services, solutions, tools and related applications, services and programs, including
          research and marketing and research activities offered by us (the "Services"), as well as the choices you have
          associated with that data. It also describes how we collect, use, disclose and otherwise process Personal
          Information collected in relation to our Services and otherwise in the course of our business activities.
        </p>
        <p>
          We use your data to provide and improve the Services. By using the Services or by agreeing to our General
          Terms and Conditions required to use certain of our Services, you agree to the collection and use of
          information in accordance with this policy. Unless otherwise defined in this Privacy Policy, terms used in
          this Privacy Policy have the same meanings as in our Terms and Conditions, accessible from
          https://firstversionist.com
        </p>

        <h3>Data Collection</h3>
        <p>
          We collect several different types of information as part of our normal business operations, your usage of the
          Services, our administration of you as a customer and to comply with local laws and regulations. We will not
          process Personal Data for other purposes than described in this Privacy Policy.
        </p>
        <h4>Types of Data Collected</h4>
        <h5>Personal Data</h5>
        <p>
          While using our Services, we may ask you to provide us with certain personally identifiable information that
          can be used to contact or identify you ("Personal Data"). Personally identifiable information may include, but
          is not limited to:
        </p>
        <ul>
          <li>Any information you send us through email or forms</li>
          <li>
            Any information you send us when creating an account, request customer support or otherwise communicate with
            us.{' '}
          </li>
        </ul>
        <p>
          The types of data we may collect include basic user information (such as name, email address, and avatar),
          company information and other information you choose to provide.
        </p>
        <p>
          When needed, we collect financial or business information from you, but only in the case of any agreement
          between parties and for the usage of invoicing, our administration of you as a customer and to comply with
          local laws and regulations.{' '}
        </p>
        <p>
          When possible, we will not collect financial information from you (such as payment/credit card number,
          expiration date or security code). All payments to us are handled via third parties, Paddle Ltd
          (https://paddle.com) and Mollie B.V. (https://mollie.com). We refer to their respective privacy statements:
          https://paddle.com/gdpr and https://www.mollie.com/en/privacy.
        </p>

        <h5>Usage Data</h5>
        <p>
          We may also collect information automatically on how the Services is accessed and used ("Usage Data"). This
          Usage Data may include information such as your computer's Internet Protocol address (e.g. IP address),
          browser type, browser version, the parts of our Services that you use, the time and date of your usage, the
          time spent on those parts, unique device identifiers and other debugging or diagnostic data.
        </p>
        <h5>Tracking & Cookies Data</h5>
        <p>
          We use cookies and similar tracking technologies to track the activity on our Services and hold certain
          information.
        </p>
        <p>
          Cookies are files with small amount of data which may include an anonymous unique identifier. Cookies are sent
          to your browser from a website and stored on your device. Tracking technologies also used are beacons, tags,
          and scripts to collect and track information and to improve and analyze our Services.
        </p>
        <p>
          You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if
          you do not accept cookies, you may not be able to use some portions of our Services.
        </p>
        <p>Examples of Cookies we use:</p>
        <ul>
          <li>
            <strong>Session Cookies</strong>. We use Session Cookies to operate our Services.
          </li>
          <li>
            <strong>Preference Cookies</strong>. We use Preference Cookies to remember your preferences and various
            settings.
          </li>
          <li>
            <strong>Security Cookies</strong>. We use Security Cookies for security purposes.
          </li>
        </ul>

        <h5>Third Party Data</h5>
        <p>
          We may also obtain Personal Information about you from third parties, such as LinkedIn, Facebook, Github,
          Twitter and other publicly accessible sources.
        </p>
        <h5>Support And Service</h5>
        <p>
          When you contact us for support or with other customer service requests, we can keep records related to such
          requests, including any information provided by you related to said requests.
        </p>
        <h5>Marketing Or Other Communications</h5>
        <p>
          We may use your personal Data to contact you with marketing or promotional materials and other communications
          related to the Services. If you no longer wish to receive marketing or promotional communications related to
          the Services, you can use the unsubscribe link in the email or by emailing contact@firstversionist.com to
          request us to stop sending you such communications. We process these requests immediately, but at most within
          two business days.
        </p>

        <h3>Use of Data</h3>
        <p>
          For individuals in the European Economic Area, our processing of your Personal Data is justified on the
          following legal bases:
        </p>
        <ul>
          <li>
            the processing is necessary to perform an agreement with you or take steps to enter into an agreement at
            your request;
          </li>
          <li>the processing is necessary for us to comply with relevant legal oblications;</li>
          <li>
            the processing is in our justified interest, and this justified interest prevails over your privacy; and/or
          </li>
          <li>you have consented to the processing.</li>
        </ul>
        <p>Firstversionist uses the collected data for various purposes:</p>
        <ul>
          <li>To perform the agreement with you and with others</li>
          <li>To process. evaluate and complete certain transactions involving the Services</li>
          <li>To operate, provide and maintain the Services</li>
          <li>To provide documentation, communications, marketing and advertising or other services you request</li>
          <li>To notify you about changes to our Services</li>
          <li>To allow you to participate in interactive features of our Service when you choose to do so</li>
          <li>To provide customer care and support</li>
          <li>To protect an ensure safety of our Intellectual Property Rights</li>
          <li>To provide analysis or valuable information so that we can improve the Service</li>
          <li>To monitor the usage of the Services</li>
          <li>To detect, prevent and address technical issues</li>
          <li>
            To manage, protect against and investigate fraud, risk exposure, claims and other liabilities, including but
            not limited to violation of our contract terms or applicable laws and regulations;
          </li>
          <li>To adhere to any legal obligations</li>
        </ul>

        <h3>Transfer Of Data</h3>
        <p>
          Your information, including Personal Data, may be transferred to — and maintained on — computers located
          outside of your state, province, country or other governmental jurisdiction where the data protection laws may
          differ than those from your jurisdiction.
        </p>
        <p>
          Your consent to this Privacy Policy followed by your submission of such information represents your agreement
          to that transfer.
        </p>
        <p>
          Firstversionist B.V. will take all steps reasonably necessary to ensure that your data is treated securely and
          in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an
          organization or a country unless there are adequate controls in place including the security of your data and
          other personal information.
        </p>

        <h3>Disclosure Of Data</h3>
        <p>
          Firstversionist and its Services operate worldwide and we may share Personal Data with our affiliated
          businesses as part of our business operations, administration of the Services and to comply with applicable
          laws and regulations. We may also appoint third party service providers (operating under our instructions) to
          assist us in providing information, products or services to you, in managing our business or in managing and
          improving our Services. We may share your Personal Data with these affiliates and third parties to perform
          services that the third parties have been engaged by us to perform on our behalf, subject to appropriate
          contractual restrictions, obligations and security measures, or if we believe it is reasonably necessary to
          prevent harm or loss, or if we believe that the disclosure will further an investigation of suspected or
          actual illegal activities.
        </p>
        <p>
          We reserve the right to share any data that is not deemed Personal Data or is not otherwise subject to
          contractual restrictions.
        </p>

        <h4>Legal Requirements</h4>
        <p>
          Firstversionist B.V. may disclose your Personal Data in the good faith belief that such action is necessary
          to:
        </p>
        <ul>
          <li>To comply with a legal obligation</li>
          <li>To protect and defend the rights or property of Firstversionist B.V.</li>
          <li>To prevent or investigate possible wrongdoing in connection with the Services</li>
          <li>To protect the personal safety of users of the Services or the public</li>
          <li>To protect against legal liability</li>
        </ul>
        <p>
          Additionally we may share Personal Data with third parties in connection with potential or actual sale of our
          company or any of our assets, or those of any affiliated company, in which case any Personal Data held by us
          may be one of the transferred assets.
        </p>
        <p>
          Where Personal Data is transferred outside of the European Economic Area to our affiliated companies or third
          party service providers, we will take steps to ensure that your personal information is as well protected as
          if it remained within the European Economic Area, including by entering into data transfer agreements using
          the European Commission approved Standard Contractual Clause.
        </p>
        <p>
          We commit to resolve complaints about your Personal Data in adherence to the GDPR. Please email our Data
          Protection Officer at contact@firstversionist.com.
        </p>

        <h3>Security Of Data</h3>
        <p>
          The security of your data is important to us, but remember that no method of transmission over the Internet,
          or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to
          protect your Personal Data, we cannot guarantee its absolute security. Accordingly, we cannot be held
          responsible for unauthorized or unintended access that is beyond our control. Our personnel is only allowed to
          access or process Personal Data if this is reasonably required to do so for word related tasks, to adhere to
          any customer requests or to fulfill a legal obligation on behalf of us.
        </p>

        <h4>Account details</h4>
        <p>
          When using our service, you may be supplied with an automatically generated password. This password is not
          otherwise stored by us or in any way retrievable. We strongly encourage you to change this password
          regardless, and to use a long password made up of lowercase and uppercase letters, numbers and symbols, that
          is different from the passwords you use for other services, and that is updated periodically.
        </p>
        <h4>Transferring Personal Data</h4>
        <p>
          Where Personal Data is transferred outside of the European Economic Area to our affiliated companies or third
          party service providers, we will, as mentioned above, take steps to ensure that your Personal Data is
          protected by the same level of protection as if it remained in the European Economic Area, including by
          entering into data transfer agreements using the European Commission approved Standard Contractual Clause.
        </p>
        <h4>Personal Data Breach</h4>
        <p>
          In the case of a data breach leading to the accidental or unlawful destruction, loss, alteration, unauthorized
          disclosure of, or access to, Personal Data transferred, stored or otherwise processed by us about our
          customers, we shall where feasible and not later than 72 hours after having become aware of it, notify the
          breach to the local supervisory authority, unless the information breach is unlikely to result in a risk to
          the rights and freedoms of natural persons. When the Personal Data Breach is likely to result in a high risk
          to the rights and freedoms of natural persons we will communicate the Personal Data Breach on our security
          page and via email with the affected natural persons, unless we have already implemented appropriate technical
          and organizational protection measures (particularly encryption and other measures that render the Personal
          Data unintelligible to any person not authorised to access it) and those measures are applied to the Personal
          Data affected by the breach or when we have taken measures that ensure that the high risk to the rights and
          freedoms of persons is no longer likely to materialize.
        </p>
        <h4>Data Retention</h4>
        <p>
          Collected Personal Data is in general not stored by us for longer than three years after an active agreement,
          unless you file a deletion request prior to that. In some circumstances we may retain certain Personal Data
          for other periods of time, for instance where we are required to do so in accordance with legal, tax and
          accounting requirements, or if required by a legal process, legal authority or other governmental entity
          having authority to make the request, for as long as required. In specific circumstances we may also retain
          certain Personal Data for longer periods of time corresponding to a statute of limitations so that we have an
          accurate record of your dealings with us in the event of any complaints or challenges.
        </p>
        <h3>Service Providers</h3>
        <p>
          We may employ third party companies and individuals to facilitate our Services ("Service Providers"), to
          provide the Services on our behalf, to perform Service-related services or to assist us in analyzing how our
          Services are used.
        </p>
        <p>
          These third parties have access to your Personal Data only to perform these tasks on our behalf and are
          obligated not to disclose or use it for any other purpose.
        </p>
        <h4>Data storage and servers</h4>
        <p>
          Our Services and data are hosted on Digitalocean in European Data centers. Personal Data connected to any
          active customer accounts is stored on Airtable. This information is only accessible by people authorized to
          access the data, and usage logs are monitored and audited.
        </p>
        <h4>Acknowledgements</h4>
        <ul>
          <li>
            <strong>Google Analytics</strong>
            <br />
            Google Analytics is a web analytics service offered by Google that tracks and reports website traffic.
            Google uses the data collected to track and monitor the use of our Services. We do not track IP’s or share
            data with other Google entities. You can opt-out of having made your activity on the Services available to
            Google Analytics by installing the Google Analytics opt-out browser add-on. The add-on prevents the Google
            Analytics JavaScript (ga.js, analytics.js, and dc.js) from sharing information with Google Analytics about
            visits activity. For more information on the privacy practices of Google, please visit the Google Privacy &
            Terms web page: https://policies.google.com/privacy?hl=en
          </li>
          <li>
            <strong>Sentry</strong>
            <br />
            We use Sentry to track errors that occur in our Services. This includes certain data that correlates with
            the error, but does not include customer information,
          </li>
          <li>
            <strong>Mailchimp</strong>
            <br />
            We use Mailchimp to send newsletters and other promotional email. These emails are only sent to customers
            who gave explicit permission for us to do so.
          </li>
          <li>
            <strong>Amazon Web Services</strong>
            <br />
            We use Amazon Web Services to send transactional email and to manage account authorization.
          </li>
        </ul>

        <h4>Payments / Credit Cards</h4>
        <p>
          Firstversionist does not store any credit card information on our servers. All automatic payment processing is
          handled by Paddle or by Mollie.
        </p>
        <h4>Communication and Encryption</h4>
        <p>
          We use HTTPS and SSL for all our resources, websites and apis that are part of our Services or marketing
          efforts. This means that all information shared with us or with our Services are securely encrypted during
          transport.
        </p>
        <h4>Reporting Security Problems</h4>
        <p>
          If you find a security issue or vulnerability please contact us immediately at contact@firstversionist.com.
        </p>
        <h3>Links To Other Sites</h3>
        <p>
          Our Services may contain links to other sites that are not operated by us. If you click on a third party link,
          you will be directed to that third party's site. We strongly advise you to review the Privacy Policy of every
          site you visit.
        </p>
        <p>
          We have no control over and assume no responsibility for the content, privacy policies or practices of any
          third party sites or services.
        </p>
        <h3>Children's Privacy</h3>
        <p>
          Our Services do not address anyone under the age of 16 ("Children"). We do not knowingly collect personally
          identifiable information from anyone under the age of 16. If you are a parent or guardian and you are aware
          that your Children has provided us with Personal Data, please contact us. If we become aware that we have
          collected Personal Data from children without verification of parental consent, we take steps to remove that
          information from our servers.
        </p>

        <h3>Your rights</h3>
        <p>Based on the GDPR you may have rights available to you in respect of your Personal Data, such as:</p>
        <ul>
          <li>
            to obtain a copy of your Personal Data together with information about how and on what basis that Personal
            Data is processed;
          </li>
          <li>to rectify inaccurate Personal Data (including the right to have incomplete Personal Data completed);</li>
          <li>
            to delete your Personal Data (where it is no longer necessary in relation to the purposes for which it was
            collected or processed). We strive to delete or anonymize your Personal Data where possible within 30 days
            after your deletion request;
          </li>
          <li>to restrict processing of your Personal Data under certain circumstances.</li>
          <li>
            to port your Personal Data in machine-readable format to a third party (or to you) when we justify our
            processing on the basis of your consent or the performance of an agreement with you;
          </li>
          <li>
            to withdraw your consent to our processing of your Personal Data (where that processing is based on your
            consent);
          </li>
          <li>
            to obtain, or see a copy of the appropriate safeguards under which your Personal Data is transferred to a
            third country or international organization outside of the European Economic Area; and
          </li>
          <li>to lodge a complaint with your local supervisory authority for data protection.</li>
        </ul>

        <p>
          In addition to the above rights, you have the right to object, on grounds relating to your particular
          situation, at any time to any processing of your Personal Data which we have justified on the basis of a
          legitimate interest, including profiling (as opposed to your consent) or to perform a contract with you. You
          also have the right to object at any time to any processing of your Personal Data for marketing or promotional
          purposes, including profiling for marketing or promotional purposes.
        </p>
        <p>
          In relation to all of these rights or if you have any questions about this Privacy Policy, please send an
          email to contact@firstversionist.com. Please note that we may request proof of identity, and we reserve the
          right to charge a fee where permitted by law, especially if your request is manifestly unfounded or excessive.
          We will endeavor to respond to your request within all applicable timeframes.
        </p>
        <h3>Changes To This Privacy Policy</h3>
        <p>
          We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new
          Privacy Policy on this page and revising the date at the top of the policy. In some cases we may provide you
          with additional notifications of changes to the Privacy Policy.
        </p>
        <p>
          You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are
          effective when they are posted on this page.
        </p>
      </Wrap>
      <Footer />
    </>
  );
};

export default NotFoundPage;
